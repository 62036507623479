import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src='../images/EKxpress.svg' className="App-logo" alt="logo" />        
        <p>Finance API</p>
      </header>
    </div>
  );
}

export default App;
